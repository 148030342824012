<template>
<div>
    <!--TOP part section -->
    <section class="bannersc minscheight">
    <div class="container">
      <div class="row">
        <div class="col-md-3 laptopshow">
           <div class="howimg">
             <img src="@/assets/images/shape-20.svg" alt="" class="himg1">
             <img src="@/assets/images/shape-22.svg" alt="" class="himg2">
           </div>
        </div>
        <div class="col-md-12 mobileshow">
           <div class="howimg mobilescimg">
             <img src="@/assets/images/shape-20.svg" alt="" > 
           </div>
        </div>
        <div class="col-md-6 laptopshow">
          <div class="banner-text text-center">
              <h1 class="why" v-html="$t('whyPage.banner.bannertitle')"></h1>  
            
            <p class="why"> {{$t('whyPage.banner.bannertext')}}</p>
            
          </div>
        </div> 

        <div class="col-md-12 mobileshow">
          <div class="banner-text text-center">
              <h1 class="why" v-html="$t('whyPage.banner.bannertitle')"></h1> 
            
            
            <p> {{$t('whyPage.banner.bannertext')}}</p>
            
          </div>
        </div>


        <div class="col-md-3 laptopshow">
          <div class="howimg">
             <img src="@/assets/images/shape-21.svg" alt="" class="himg3">
             <img src="@/assets/images/shape-23.svg" alt="" class="himg4">
           </div>
        </div>
      </div>
    </div>
  </section>
    <!--END-->  
 <!--Main Content section START-->
 <section class="mainsc_content">
   <div class="container">
     
     <!--section title -->
     <div class="row">
       <div class="col-md-12">
         <div class="section_title sctitle2">
           <h2>{{$t('whyPage.process.processSectionTitle')}}</h2> 
         </div>
       </div>
     </div>
     <!--END-->

     <!--flow-->
     <div class="row">
       <!--box flow-->
       <div class="col-md-4">
         <div class="boxflow onebox">
           <div class="flowstep1"><img src="@/assets/images/line-number-01-02.svg" alt=""></div>
           <img src="@/assets/images/icon/icon-7.svg" alt="">
           <h3>{{$t('whyPage.process.stepOneTitle')}}</h3>
           <p>{{$t('whyPage.process.stepOneInfo')}}</p>
         </div>
       </div>
       <!--end-->
       <!--box flow-->
       <div class="col-md-4">
         <div class="boxflow">
             <div class="flowstep2"><img src="@/assets/images/line-number-03.svg" alt=""></div>
           <img src="@/assets/images/icon/icon-8.svg" alt="">
           <h3>{{$t('whyPage.process.stepTwoTitle')}}</h3>
           <p>{{$t('whyPage.process.stepTwoInfo')}}</p>
         </div>
       </div>
       <!--end-->
       <!--box flow-->
       <div class="col-md-4">
         <div class="boxflow">
           <img src="@/assets/images/icon/icon-9.svg" alt="">
           <h3>{{$t('whyPage.process.stepThreeTitle')}}</h3>
           <p>{{$t('whyPage.process.stepThreeInfo')}}</p>
         </div>
       </div>
       <!--end-->
     </div>
     <!--end -->
 

 <!--section title -->
     <div class="row">
       <div class="col-md-12">
         <div class="section_title sctitle2 sctop-1">
           <h2>{{$t('whyPage.reviewProcessTitle')}}</h2> 
           <p>{{$t('whyPage.reviewProcessDetails')}}</p>
         </div>
       </div>
     </div>
     <!--END-->

     <!--number-->
     <div class="row sc-space-1">
       <div class="col-md-4">
         <div class="boxnumber">
           <img src="@/assets/images/dot-001.svg" alt=""> 
         </div>
       </div>
       <div class="col-md-4">
         <div class="boxnumber">
           <img src="@/assets/images/dot-002.svg" alt=""> 
         </div>
       </div>
       <div class="col-md-4">
         <div class="boxnumber">
           <img src="@/assets/images/dot-003.svg" alt=""> 
         </div>
       </div>
     </div>
     <!--end-->

 
      <div class="row scmar-50">
        <div class="col-md-2">
            <div class="colimgtabsc"><img src="@/assets/images/shape-34.svg" alt=""></div>       
        </div>
        <div class="col-md-5">
            <div class="tab3consc">
                <h4 class="tabnumber1"><span>{{$t('whyPage.step1.title')}}</span></h4>
                <h5> {{$t('whyPage.step1.subTitle')}}</h5>
                <p v-html="$t('whyPage.step1.detailsInfo')"></p> 
              </div>
        </div>
        <div class="col-md-5">
          <div class="tab3consc">
                  <h4 class="tabnumber2"><span>{{$t('whyPage.step2.title')}}</span></h4>
                <h5> {{$t('whyPage.step2.subTitle')}}</h5>
                <p v-html="$t('whyPage.step2.detailsInfo')"></p> 
              </div>
        </div>
      </div>

     <div class="row scmar-50">
        <div class="col-md-2 svmb"> 
            <div class="colimgtabsc"><img src="@/assets/images/shape-35.svg" alt=""></div>
        </div>
        <div class="col-md-5">
            <div class="tab3consc">
                <h4 class="tabnumber3"><span>{{$t('whyPage.step3.title')}}</span></h4>
                <h5> {{$t('whyPage.step3.subTitle')}}</h5>
                <p v-html="$t('whyPage.step3.detailsInfo')"></p> 
              </div>
        </div>
        <div class="col-md-5">
          <div class="tab3consc">
                  <h4 class="tabnumber4"><span>{{$t('whyPage.step4.title')}}</span></h4>
                    <h5> {{$t('whyPage.step4.subTitle')}}</h5>
                    <p v-html="$t('whyPage.step4.detailsInfo')"></p> 
                  </div>
        </div>
      </div>


 <!--section title -->
     <div class="row">
       <div class="col-md-12">
         <div class="section_title sctitle2 sc3">
           <h3>{{$t('whyPage.letUs')}}</h3> 
              <router-link :to="`/${$i18n.locale}/contact-us`" class="get_scbtn4">{{$t('whyPage.btn')}}</router-link>
         </div>
       </div>
     </div>
     <!--END-->


     

     <!--boxstyle2-->
    <div class="row text-2col-box whysec align-items-center">
      <div class="col-md-6 order-1 order-md-12">
        <div class="textboxsc whysc">
            <h3 class="reduce"><span>{{$t('whyPage.fsSectionTitle')}}</span></h3> 
            <p v-html="$t('whyPage.fsSectionDetails')"> </p> 
        </div>
      </div>
      <div class="col-md-6 order-12 order-md-1">
        <div class="box-col-sc_img drf5"> 
          <img src="@/assets/images/img-220.png" alt=""></div>
      </div>
    </div>
     <!--END-->

     <!--boxstyle2-->
    <div class="row text-2col-box whysec align-items-center"> 
      <div class="col-md-7">
        <div class="box-col-sc_img drf6"> 
          <img src="@/assets/images/img-221.png" alt=""></div>
      </div>
      <div class="col-md-5">
        <div class="textboxsc box-second">
            <h3>{{$t('whyPage.lastSectionTitle')}}</h3> 
             <div v-html="$t('whyPage.lastSectionDetails')"></div>  
        </div>
      </div>
    </div>
     <!--END-->

     <!--section title -->
     <div class="row">
       <div class="col-md-12">
         <div class="section_title sctitle2 sc4">
           <h3>{{$t('whyPage.faqTitle')}}</h3>  
         </div>
       </div>
     </div>
     <!--END-->

     <!--FAQ start -->
      <div class="row spacFaq">
       <div class="col-md-12">
         <div class="faqsc">
            <div class="accordion" role="tablist">

              <b-card no-body v-for="(items,index) in faqs" :key="items.index">
                <b-card-header header-tag="header" role="tab">
                  <b-button block v-b-toggle="'accordion'+index" variant="info">{{ items.faqQuestion }}</b-button>
                </b-card-header>
                <b-collapse 
                 :id="'accordion'+index"
                  visible accordion="my-accordion" role="tabpanel">
                  <b-card-body> 
                    <b-card-text v-html="items.faqAnswer"> </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card> 
              
              
            </div>
         </div>
       </div>
     </div>
     <!--faq END -->
 
   </div>
 </section>
 <!--END main section -->
<!--call a section start-->
 <section class="callactionsc">
   <div class="container">
     <div class="row">
       <div class="col-md-12">
          <h3>{{$t('callActionHome.title')}}</h3>
          <div v-html="$t('callActionHome.content')"></div>  
           <router-link :to="`/${$i18n.locale}/contact-us`" class="get_scbtn">{{$t('callActionHome.btn')}}</router-link>
       </div>
     </div>
   </div>
 </section>
 <!--End call a section -->
</div>
</template>

<script>
import MainPage from '@/layouts/Main_Layout.vue' 
import eng from '../locales/faq_en.json'
import ita from '../locales/faq_it.json'
  export default {
    data: () => ({
      eng: eng,
      ita: ita,
    }),
    computed: {
      faqs() {
        return this.$i18n.locale === 'it' ? this.ita : this.eng;
      },
    },
    //this for layout call
        created() {
            this.$parent.$emit('update:layout', MainPage);
        },
        //this for layout render
        render() {
            return this.$slots.default[0];
        }, 
     //Meta info title + description + others meta
    metaInfo() {
          return {  
                title: this.$t('metaInfo.whyTitle'),
                 htmlAttrs: {
                  lang: this.$i18n.locale,
                },
                meta: [
                    { name: 'description', content:  this.$t('metaInfo.whyDesc')}  
                ] 
          }
      }
     //end meta info
  }
</script>

<style lang="scss" scoped>

</style>